/** @format */

.exchangeBox {
  width: 50%;
  height: 75vh;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  background-color: white;
  position: relative;
  margin: auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.leftExchangeDiv {
  width: 50%;
  background-color: #e3e3e3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exchangeImg {
  width: 100%;
  height: 70%;
  object-fit: cover;
  background-color: #e3e3e3;
}

.rightExchangeDiv {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exchangeLabel {
  margin: 0;
  margin-top: 15px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: medium;
  margin-left: 15px;
  margin-right: 15px;
}

.exchangeLabel2 {
  margin: 0;
  margin-top: 15px;
  text-align: center;
  font-size: small;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
}

.exchangeCoinDiv {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 10px;
  align-items: center;
  display: flex;
  background-color: white;
}

.exchangeCoinImg {
  position: relative;
  margin: 0; /* This will push the element to the right */
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 10;
}
.exchangeCoinLabel {
  margin: 0;
  margin-left: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.exchangeButton {
  width: 80%;
  height: 35px;
  margin-left: auto; margin-right: auto;
  background-color: #5d3fd3;
  color: white;
  border: none;
  margin-top: 15px;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ccc;
}

.exchangeButton:hover {
    
}
  
.exchange-container {
  position: relative;
  width: calc(95%);
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  display: flex;
  gap: 15px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.cashLabel{
  margin: 0; 
  margin-top: 5px; 
  font-weight: bold;
}

.exchangeTable{
  position: relative;
  width: 20%; /* Set each item to occupy 20% of the container width */
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
}

.cashImg{
  max-width: 200px;
  height: 200px;
  object-fit: cover;
}

.cashView{
  width: 100%;
  height: 1px; 
  background-color: #e3e3e3;
}

.cashTable:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.priceDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}

.coinImg2{
  position: relative;
  margin: 0; /* This will push the element to the right */
}

.cashLabel2{
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Use a cartoonish font */
  color: #ba55d3;
  font-weight: bold; /* Make the text bold */
  margin-left: 3px;
  text-transform: uppercase; /* Uppercase the text for a cartoonish look */
}

.errorCoinLabel{
  font-size: small;
  text-align: center;
  color: red;
  font-weight: bold;
}

.FooterDiv {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  background-color: black;
}

.copyrightLabel2 {
  margin: 0;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
}

.homeFooterDiv {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  background-color: black;
}
