.accountSettingsDiv {
  display: flex;
}

.accProfileIcon {
  position: relative;
  margin-top: 15px; margin-right: auto; margin-left: 55px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
}

.accCountryIcon {
  position: relative;
  margin-top: 15px; margin-left: auto; margin-right: 55px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
}

.accountInputDiv{
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 15px;
}

.accountSettingsLabel {
  position: relative;
  margin-left: 8%;
  font-size: small;
  color: black;
  font-style: bold;
  font-family: Helvetica;
}

.accountSettingsInput {
  margin: auto;
  height: 25px;
  font-size: 100%;
  width: 80%;
  padding-left: 20px;
  position: relative;
  border-radius: 6px;
  border: solid #e3e3e3;
  pointer-events: none;
  user-select: none;
  font-family: Helvetica;
}