/** @format */

.loginBody {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #e3e3e3, 50%, #5d3fd3 50%);
}

.introBox {
  background-repeat: no-repeat;
  width: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
}

.introImage {
  margin: auto;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.introLabel {
  position: relative;
  top: 150px;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  color: white;
}

.introExplain {
  position: relative;
  top: 155px;
  text-align: center;
  font-size: medium;
  margin-left: 25px;
  margin-right: 25px;
  color: white;
  font-size: 20px;
}

.google_promo {
  margin: auto;
  width: 35%;
  height: 12%;
  position: absolute;
  top: 65%;
  left: 0;
  right: 0;
  cursor: pointer;
  object-fit: cover;
}

.apple_promo {
  margin: auto;
  width: 34%;
  height: 9.5%;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  cursor: pointer;
  object-fit: cover;
}

.loginInput {
  width: 50%;
  position: absolute;
  margin: auto;
  right: 0px;
  top: 0%;
  height: 100%;
}

.loginBox {
  margin: auto;
  width: 75%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.imageBox {
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 10px;
}

.loginBox input[type="username"] {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  margin-top: 5%;
  padding-left: 20px;
  width: 85%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 3%;
  width: 100%;
}

.loginPassword {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  color: black;
  font-size: 100%;
  position: relative;
  padding-left: 20px;
  width: 85%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.toggleEye {
  height: 30px;
  width: 25px;
  color: gray;
  position: absolute;
  margin: none;
  top: 50%;
  transform: translateY(-28%); 
  right: 7%;
  cursor: pointer;
}

/* .loginBox:hover *:not(.maintenanceText) {
  opacity: 0; /* Hide elements inside .loginBox except .loginForgot */
  /* pointer-events: none; */
/* } */

/* .loginBox:hover .maintenanceText{
  opacity: 1;
} */ 

.maintenanceText {
  margin: 0%;
  position: absolute;
  top: 50%; /* Position the top of the element at the vertical center of the parent */
  transform: translateY(-50%); /* Adjust to vertically center the element */
  left: 10%; /* Adjust the horizontal position as needed */
  right: 10%; /* Adjust the horizontal position as needed */
  color: black;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  text-align: center;
  border: 2px solid whitesmoke;
  border-radius: 16px;
  padding: 15px;
  opacity: 0;
}

.loginBox input:focus {
  border-color: #5d3fd3;
  outline-color: #5d3fd3;
}

.loginForgot {
  color: #5d3fd3;
  font-weight: bold;
  position: relative;
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
}

.loginButton {
  margin-left: auto; margin-right: auto;
  height: 40px;
  width: 90%;
  color: white;
  font-size: 100%;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.loginRegister {
  color: #5d3fd3;
  font-weight: bold;
  position: relative;
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
}

.loginGoogle {
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  background-color: white;
  width: 90%;
  margin-top: 0; margin-left: 5%; margin-right: 5%; margin-bottom: 20px;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.googleImage {
  position: absolute;
  left: 6px;
  top: 50%;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
}

.lineView {
  position: relative;
  width: 50%;
  height: 0px;
  border: 1px solid darkgray;
}

.registerDialog {
  position: absolute;
  top: 50%;
  width: 35%;
  height: 75%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
}

.registerLabel {
  position: absolute;
  left: 5%;
  top: 21px;
  font-size: medium;
  font-style: normal;
}

.registerCancel {
  position: absolute;
  right: 5%;
  top: 30px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.registerDialog input[type="email"] {
  margin: auto;
  height: 10%;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 20%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
}
.registerPassword {
  margin: auto;
  height: 10%;
  color: black;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 35%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.registerDialog input[type="firstname"] {
  margin: auto;
  height: 10%;
  width: 35%;
  color: black;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 50%;
  left: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.registerDialog input[type="surname"] {
  margin: auto;
  height: 10%;
  width: 35%;
  color: black;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 50%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.registerDialog input[type="date"] {
  margin: auto;
  height: 10%;
  width: 35%;
  color: black;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 65%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: text;
}

.registerDialog input:focus {
  border-color: #5d3fd3;
  outline-color: #5d3fd3;
}

.registerDialog::backdrop {
  backdrop-filter: blur(1px);
}

.registerButton {
  margin: auto;
  height: 10%;
  color: white;
  font-size: 100%;
  position: absolute;
  background-color: #5d3fd3;
  top: 80%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.applyDiv{
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.applyText{
  text-align: center;
  font-weight: bold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.applyButton{
  margin: auto;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  background-color: gainsboro;
  border-radius: 6px;
  padding-left: 25px; padding-right: 25px;
  border: solid darkgray;
  cursor: pointer;
}

.applyButton:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.forgetDialog {
  position: absolute;
  top: 50%;
  width: 35%;
  height: 75%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
}

.forgetLabel {
  position: absolute;
  left: 5%;
  top: 21px;
  font-size: medium;
  font-style: normal;
}

.forgetCancel {
  position: absolute;
  right: 5%;
  top: 30px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.forgetLabel2 {
  position: relative;
  top: 30%;
  text-align: center;
  font-size: small;
  color: #9400d3;
}

.forgetDialog input[type="email"] {
  margin: auto;
  height: 10%;
  font-size: 100%;
  padding-left: 20px;
  position: absolute;
  top: 40%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.forgetDialog input:focus {
  border-color: #5d3fd3;
  outline-color: #5d3fd3;
}

.forgotButton {
  margin: auto;
  height: 10%;
  color: white;
  font-size: 100%;
  position: absolute;
  background-color: #5d3fd3;
  top: 55%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.forgotButton2 {
  margin: auto;
  height: 10%;
  color: white;
  font-size: 100%;
  position: absolute;
  background-color: #5d3fd3;
  top: 55%;
  left: 5%;
  right: 5%;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
  pointer-events: none;
}

.forgetSent {
  position: relative;
  top: 60%;
  text-align: center;
  font-size: small;
  color: #50c878;
  visibility: hidden;
}

.forgetSent2 {
  position: relative;
  top: 60%;
  text-align: center;
  font-size: small;
  color: #50c878;
}

.registerEye {
  margin: auto;
  height: 30px;
  width: 25px;
  color: black;
  position: absolute;
  top: 39%;
  right: 8%;
  cursor: pointer;
  /* display: none; */
}

.registeredEye {
  margin: auto;
  height: 30px;
  width: 25px;
  color: black;
  position: absolute;
  top: 39%;
  right: 8%;
  cursor: pointer;
  /* display: none; */
}

.hidden {
  display: none;
}

.footer {
  position: absolute;
  height: 10%;
  margin-top: 100vh;
  left: 0%;
  right: 0%;
  background-color: white;
}

.copyrightLabel {
  position: relative;
  top: 45%;
  text-align: center;
  font-size: x-small;
  color: #454545;
}

.privacyLabel {
  position: absolute;
  top: 0%;
  left: 41%;
  text-align: center;
  font-size: small;
  color: #ba55d3;
  cursor: pointer;
}

.tosLabel {
  position: absolute;
  top: 0%;
  right: 39%;
  font-size: small;
  color: #ba55d3;
  cursor: pointer;
}

.blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  pointer-events: none;
}

.loginFooterDiv {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  top: 100%;
  background-color: black;
}

@media (max-width: 900px) {
  .loginMobileBox {
    position: absolute; /* Allows positioning relative to the viewport */
    top: 50%; /* Moves the top of the box to the middle of the viewport */
    left: 50%; /* Moves the left side of the box to the middle of the viewport */
    transform: translate(-50%, -50%); /* Centers the box by offsetting half of its dimensions */
    height: fit-content; /* Sets the height to 75% of the viewport height */
    width: 70%; 
    border: solid 1px #e3e3e3;/* Sets the width to 80% of the viewport width */
    background-color: white; /* Optional: background color for visualization */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow for styling */
    border-radius: 8px; /* Optional: rounded corners */
    display: flex;
    flex-direction: column;
    padding: 35px;
  }

  .loginMobileHeaderDiv{
    display: flex;
    align-items: center;
  }

  .loginMobileIcon{
    margin: 0;
    width: 50px;
    height: 50px;
  }

  .loginHeaderTitle{
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .loginMobileUsernameInput{
    position: relative;
    margin: 0;
    margin-top: 25px;
    height: 50px;
    font-size: 100%;
    padding-left: 20px;
    border-radius: 6px;
    border: solid #e3e3e3;
  }

  .loginMobilePasswordInput{
    position: relative;
    margin: 0;
    margin-top: 25px;
    height: 50px;
    font-size: 100%;
    padding-left: 20px;
    border-radius: 6px;
    border: solid #e3e3e3;
    
  }

  .loginMobileOptionsDiv{
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .loginMobileForgot {
    margin: 0;
    color: #5d3fd3;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
  }

  .loginMobileCreate{
    margin: 0;
    color: black;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
  }
  .loginMobileButton{
    margin: 0 auto; /* Center them if needed */
    margin-top: 25px;
    height: 40px;
    width: 95%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    display: block;
  }

  .loginMobileGoogle {
    margin: 0 auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    background-color: white;
    width: 95%;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
  }

  .forgotPasswordMobileBox {
    position: absolute; /* Allows positioning relative to the viewport */
    top: 50%; /* Moves the top of the box to the middle of the viewport */
    left: 50%; /* Moves the left side of the box to the middle of the viewport */
    transform: translate(-50%, -50%); /* Centers the box by offsetting half of its dimensions */
    height: fit-content; /* Sets the height to 75% of the viewport height */
    width: 70%; 
    border: solid 1px #e3e3e3;/* Sets the width to 80% of the viewport width */
    background-color: white; /* Optional: background color for visualization */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow for styling */
    border-radius: 8px; /* Optional: rounded corners */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
  }

  .forgotPasswordMobileLabel{
    margin: 0;
    margin-top: 30px;
    text-align: center;
  }

  .loginMobileForgotEmailInput{
    position: relative;
    margin: 0;
    margin-top: 10px;
    height: 50px;
    font-size: 100%;
    padding-left: 20px;
    border-radius: 6px;
    border: solid #e3e3e3;
  }

  .loginMobileForgotPasswordButton{
    margin: 0 auto; /* Center them if needed */
    margin-top: 25px;
    height: 40px;
    width: 95%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    display: block;
  }

  .loginInput {
    display: none;
  }

  .introBox {
    width: 100%;
  }

  .loginBody {
    position: absolute;
    margin: auto;
    background: #5d3fd3;
  }

  .google_promo {
    margin: auto;
    width: 150px;
    height: 75px;
    position: absolute;
    top: 65%;
    left: 0;
    right: 0;
    cursor: pointer;
    object-fit: cover;
  }
  
  .apple_promo {
    margin: auto;
    width: 150px;
    height: 75px;
    position: absolute;
    top: 73%;
    left: 0;
    right: 0;
    cursor: pointer;
    object-fit: cover;
  }

}
