.Background{
    background-color: #5d3fd3;
    height: 100vh; /* 100% of the viewport height */
    width: 100%; /* 100% of the parent element's width */
}

.divBox {
  margin: auto;
  width: 40%;
  height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  padding-bottom: 20px;
}

.divImage {
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 10px;
}

.divInput {
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 85%;
    border-radius: 6px;
    border: solid #e3e3e3;
    
  }
  
  .divInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }

  .divLabel{
    font-weight: bold;
    font-size: small;
    margin-bottom: 0;
    color: #C21E56;
  }

  .password-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 3%;
    width: 100%;
  }

  .bottomLabel {
    position: relative;
    font-size: small;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    width: 80%;
    margin-top: 15px;
    color: #5d3fd3;
    visibility: hidden;
}

.divForgot {
    color: #5d3fd3;
    font-weight: bold;
    position: relative;
    margin-left: auto;
    margin-right: 5%;
    margin-bottom: 0;
    cursor: pointer;
  }
  
.divButton {
    margin-left: auto; margin-right: auto;
    height: 40px;
    width: 90%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    margin-top: 15px;
  }

  .loginFooterDiv {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100%;
    background-color: black;
  }
  