.balanceSettingLabel {
  font-size: small;
  font-weight: bold;
}

.paymentBalanceDiv {
  display: flex;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 20%;
  background-color: #e3e3e3;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.balanceSettingIcon {
  margin: 0;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.balanceSettingDivLabel {
  margin: 0;
  font-weight: 500;
  font-size: medium;
  margin-left: 5px;
  color: black;
}

.balanceDollarButton {
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  width: 20%;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
  margin-bottom: 10px
}
