.fullScreenImg {
    position: absolute;
    margin: auto;
    max-width: 80%; /* Adjust the percentage to fit your design */
    max-height: 80%; /* Limits the height to fit vertically */
    object-fit: contain; /* Ensures the entire image is visible without distortion */
    border-radius: 16px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional for better visual appearance */
}
