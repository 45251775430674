/** @format */

.applicationDiv {
  background-color: gainsboro;
  height: 100vh; /* 100% of the viewport height */
  width: 100%;
  display: flex;
}

.applicationBoxDiv {
  margin: auto;
  width: 40%;
  height: 60%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applicationLabel {
  margin: 0;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.applicationButton {
  height: 10%;
  color: white;
  font-size: 100%;
  width: 90%;
  margin-top: 15px;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.applicationButton:hover {
  background-color: white;
  color: black;
}

.applicationImageBox {
  width: 50px;
  height: 50px;
  position: relative;
  left: 15px;
  margin-top: 5px;
}

.applicationheader {
  display: flex;
  height: fit-content;
  align-items: center;
  margin-top: 10px;
}
.applicationHeaderLabel {
  font-size: large;
  margin-left: 10px;
  font-weight: bold;
}
