/** @format */

.manageDiv {
  position: absolute;
  margin: auto;
  top: 61px;
  left: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  width: 90%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.manageGrid {
    width: 100%;
    height: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 20px), 1fr)); /* Adjust as needed */
    gap: 20px; /* Adjust the gap between items */
    align-content: center;
  }
  
  .manageItem {
    position: relative;
    margin: auto;
    width: 250px;
    height: fit-content;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: left; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    flex-direction: column; /* Make items go from left to right */
    cursor: pointer;
  }

  .manageItem:hover{
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
.manageIcon {
    position: relative;
    margin: 0; margin-top: 10px;
    width: 80px; 
    height: 80px;
    border: 1px solid #e3e3e3;
    border-radius: 40px;
}

.manageTextDiv{
    display: flex;
    flex-direction: column;
}

.manageLabel{
    text-align: center;
    margin: 0; margin-top: 10px; 
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.manageLabel2{
    text-align: center;
    margin-left: 20px; margin-right: 20px;
    font-size: 12px;
    height: 36px;
}


.loginFooterDiv {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  top: 100%;
  background-color: black;
}
