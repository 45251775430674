.footerView{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.footerImg {
  width: 200px;
  height: 200px;
  margin-left: 15px;
} 

.footerCopyright {
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1434A4;
}

.copyrightLabel{
    position: relative;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: white;
}

.container{
  width: 100%;
  margin: auto;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
ul{
  list-style: none;
}

.footer{
  background-color: #24262b;
  padding: 70px 0;
}

.footer-col{
   width: 20%;
   padding: 0 15px;
}
.footer-col h4{
  text-align: left;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  margin-left: 37px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before{
  content: '';
  position: absolute;
  left:0;
  bottom: -10px;
  background-color: white;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child){
  margin-bottom: 10px;
}
.footer-col ul li a{
  text-align: left;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  font-size: large;
  padding-right: 20px;
  color: #ffff;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover{
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a{
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255,255,255,0.2);
  margin:0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
  color: #24262b;
  background-color: #ffffff;
}

@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
  }

  .footerImg{
    display: none;
  }
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
  }
  
  .footerImg{
    display: none;
  }
}

  