.errorDialogDiv{
    position: absolute;
    margin: auto;
    top: 0; right: 0; bottom: 0; left: 0;
    height: fit-content;
    width: 350px;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 14px;
    overflow: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bioDivLabel{
    position: relative;
    height: 250px;
    margin: 0; padding-bottom: 10px;
}

.errorCenterImage {
    max-width: 100%; /* Adjust based on your needs */
    height: auto;
  }
  
  .errorDivLabel {
    margin-top: 10px; /* Space between image and text */
    font-size: 16px;  /* Adjust text size as needed */
    font-weight: bold;
  }