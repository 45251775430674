.editDialogDiv {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 80%;
  width: 50%;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.editIconDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.editProfilePic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  object-fit: cover;
}

.editProfileLabel {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
  color: #7f00ff; /* Default color */
  cursor: pointer;
  transition: color 0.2s ease-in-out; /* Smooth transition for hover effect */
}

.editProfileLabel:hover {
  color: #6000cc; /* Darker shade of purple for hover */
}

.editProfileLabel2 {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
  color: #6082b6;
  cursor: pointer;
  transition: color 0.2s ease-in-out; /* Smooth color transition */
}

.editProfileLabel2:hover {
  color: #4a648d; /* Darker shade of the default color */
}

.editUpdateBio {
  margin: 0;
  margin-top: 35px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.editInput {
  margin: 0;
  margin-top: 10px;
  position: relative;
  width: 95%;
  border-radius: 6px;
  height: 100px;
  font-size: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  border: solid #e3e3e3;
  font-family: Helvetica;
  resize: none;
}

.editInput:focus {
  border-color: #5d3fd3;
  outline-color: #5d3fd3;
}

.editUpdateItems {
  margin: 0;
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.editProfileTable{
  position: relative;
  margin: auto;
  top: 0; bottom: 0; left: 0; right: 0;
  width: 100%;
  height: 100%;
  border-spacing: 0 15px;
}

.editProfileListDiv {
  position: relative;
  max-height: 200px;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #e3e3e3;
}

.editProfileListItemDiv {
  position: relative;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editItemsLeftContainer {
  width: 50%;
  margin: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editItemIcon {
  margin: 0;
  width: 50px;
  height: 50px;
  object-fit: fill;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 6px;
}

.editItemLeftTitle {
  margin-left: 5px;
}

.editItemName {
  font-weight: bold;
  font-size: medium;
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
  width: 60vh;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow */
}

.editItemDesc {
  font-weight: 500;
  font-size: small;
  margin-left: 5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editItemsRightContainer {
  width: 50%;
  display: flex; /* Enable flexbox */
  justify-content: flex-end; /* Align children to the right */
  align-items: center; /* Center the button vertically */
}

.editItemUpdate{
  width: 120px;
  height: 40px;
  background-color: white;
  color: #000;
  border: none;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.editItemUpdate:hover {
  background-color: #D2042D;
  color: #fff;
}



.editButtonConfirm {
  margin-top: auto; /* Push the button to the bottom */
  width: 100%;
  padding: 10px;
  background-color: #7f00ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.editButtonConfirm:hover {
  background-color: #6000cc;
}
