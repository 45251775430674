/** @format */
.paymentDetailsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.paymentDetailsIcon {
  width: 50px;
  height: 50px;
  position: relative;
}

.paymentDetailsLabel {
  margin: 0; margin-top: 5px;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.divPaypalInput {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 85%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

.divPaypalInput:focus {
  border-color: #0096FF;
  outline-color: #0096FF;
}

.paypalDetailsButton {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  width: 90%;
  color: white;
  font-size: 100%;
  position: relative;
  background-color: #0096FF;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
  margin-top: 15px;
}

.cardDetailsButton {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  width: 90%;
  color: white;
  font-size: 100%;
  position: relative;
  background-color: black;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
  margin-top: 15px;
}