    .confirmSaleDialogBox {
    margin: auto;
    width: 40%;
    height: fit-content;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border: solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    padding: 20px;
  }
  
  .confirmSaleLabel{
    width: 95%;
    text-align: start;
    color: black;
    font-weight: bold;
  }

  .confirmSaleImg{
    width: 95%;
    height: 30vh;
    background-color: #e3e3e3;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
  }


  .confirmationSaleInput {
    position: relative;
    margin: auto;
    margin-bottom: 15px;
    height: 45px;
    color: black;
    font-size: 100%;
    padding-left: 20px;
    width: 95%;
    border-radius: 6px;
    border: solid #e3e3e3;
    box-sizing: border-box; /* Ensures padding and border are included in width */
  }
  
  .confirmationSaleInput input {
    width: 100%; /* Full width of the parent container */
    height: 100%; /* Match the height of the parent container */
    padding: 0; /* Remove default padding if any */
    box-sizing: border-box; /* Ensures padding and border are included in width */
    border: none; /* Remove border if you only want the container's border */
  }

  .confirmationSaleButton {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 5px;
    height: 40px;
    width: 95%;
    color: black;
    font-size: 100%;
    position: relative;
    background-color: white;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
  }

  .confirmationSaleButton:hover {
    color: white;
    background-color: black;
    border: solid gray;
  }
  
  