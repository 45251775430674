.darkenDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8 ); /* Adjust the opacity value (0.5 in this example) for the desired darkness */
    z-index: 999; /* Ensure the element appears on top of other content */
    user-select: none;
    display: flex;
    align-items: center;
    
}

.paymentLabel {
    margin: 0%; margin-left: 15px; margin-right: 15px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 15px;
}

.payment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .payment-element {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .submit-button {
    background-color: #5d3fd3;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: black;
  }
  
    
  .checkoutFormButtonDiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px; padding-right: 15px;
  }
  .cancel_buy-button {
    background-color: white;
    color: black;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
  }
  
  .cancel_buy-button:hover {
    background-color: black;
    color: white;
  }
  
  