/** @format */
.searchContainer{
    display: flex;
    align-items: center;
}

.auctionInput{
  margin: 25px;
  width: 100%;
}

.auctionDiv{
  margin-left: 25px; margin-right: 25px; margin-bottom: 25px;
  border-radius: 16px;
  min-height: 60vh;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: start;
}

.auctionTableDiv{
  margin: 0; margin-top: 10px;
  width: 100vh;
  height: fit-content; 
  padding-top: 10px; padding-bottom: 10px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
}

.auctionIcon{
  width: 40px;
  height: 40px;
  margin-left: 10px;
  object-fit: cover;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  border-radius: 50%;
}

.auctionTextDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.auctionName {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 10px; margin-bottom: 2px;
}

.auctionDesc {
  position: relative;
  margin: 0;
  margin-top: 2px;
  font-size: 13px;
  margin-left: 10px;
  text-align: start;
  white-space: nowrap; /* Ensures text does not wrap */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Displays ellipsis (...) when text overflows */
}
.auctionRightDiv {
  display: flex;
  align-items: center;
  margin-left: auto; margin-right: 20px;
}

.auctionMoney {
  font-weight: bold;
  font-size: 15px;
  margin: 0; margin-right: 10px;
}

.auctionButton {
  height: fit-content;
  width: fit-content;
  padding: 10px; padding-left: 25px; padding-right: 25px;
  color: white;
  font-size: 14px;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.auctionButton:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #D70040;
  color: #e3e3e3;
}