.addDigitalDiv {
    position: absolute;
    margin: 0;
    top: 61px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
  
  .addLeftDiv,
  .addRightDiv {
    flex: 1; /* Each div takes up equal space */
    width: 50%; /* Each div takes up 50% of the parent container */
  }
  
  .addLeftDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .addImageDiv{
    display: flex;
    align-items: center;
  }

  .addDigitalMiddleImage{
    width: 50px;
    height: 50px;
    background-color: #e3e3e3;
    cursor: pointer;
    object-fit: cover;
  }

  .addMusicDiv{
    width: 350px;
    height: 50px;
    background-color: white;
    border: 1px solid whitesmoke;
    border-top-right-radius: 4px; border-bottom-right-radius: 4px;
    cursor: pointer;
    object-fit: cover;
    display: flex;
    justify-content: space-between;
  }

  .addMusicRightIcon{
    width: 30px;
    height: 30px;
    margin-top: auto; margin-bottom: auto; margin-right: 5px;
    cursor: pointer;
  }

  .textMusicDiv{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .textMusicLabel{
    margin: 0%; margin-top: 5px; margin-left: 5px;
    font-size: small;
    font-weight: bold;
    text-align: left;
    width: 250px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
  }

  .textMusicLabel2{
    margin: 0%; margin-bottom: 5px;  margin-left: 5px;
    font-size: small;
    font-weight: 500;
    text-align: left;
  }

  .addLeftIcon{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
    filter: invert(28%) sepia(95%) saturate(0%) hue-rotate(199deg) brightness(101%) contrast(92%);
  }

  .addRightIcon{
    height: 40px; width: 40px;
    margin-left: 10px;
    cursor: pointer;
    filter: invert(28%) sepia(95%) saturate(0%) hue-rotate(199deg) brightness(101%) contrast(92%);
  }

  .addLeftIcon:hover, .addRightIcon:hover{
    filter: none;
  }

  .add-Digital-container{
    width: 60vh; height: fit-content;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .addDigitalicon{
    width: 30px;
    height: 30px;
    margin-top: 15px;
    margin-left: 10px; margin-right: 10px;
    border: 1px solid darkgrey;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
  }

  .addDigitalimage{
    width: 50px;
    height: 50px;
    margin-left: 10px;
    border: 1px solid darkgray;
    border-radius: 8px;
    cursor: pointer;
  }

  .selectedImg {
    border: 2px solid #5d3fd3;
    border-radius: 8px; /* Adjust the border radius as needed */
  }

  .addRightDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow-y: auto;
  }
  
  .addRightDigital{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .addLeftDigital{
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }


  .addDigitalLabel{
    margin: 0; 
    font-weight: bold;
  }
  
  .addDigitalInput{
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 10px; margin-bottom: 10px;
    padding-left: 20px;
    width: 50vh;
    border-radius: 6px;
    border: solid #e3e3e3;
    -moz-appearance: textfield;
  }

  .addDigitalInput::-webkit-inner-spin-button,
  .addDigitalInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .addDigitalInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }

  .addDigitalDescription:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }
  
  .addDigitalDescription{
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 5px; margin-bottom: 5px;
    padding-left: 20px; padding-top: 20px; padding-bottom: 20px; padding-right: 20px;
    width: 50vh;
    border-radius: 6px;
    border: solid #e3e3e3;
  }

  .DigitalQuantity {
    position: relative;
    width: 57vh;
    height: 40px;
    background-color: 	white;
    color: #000;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 50;
    margin-top: 10px; margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    text-align: left;
    padding-left: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    appearance: none;
    background-image: url("./../img/downarrow.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
  }
  
  .DigitalQuantity:focus{
    outline: 2px solid #5d3fd3;
  }
  
  .dropdown-content {
    display: none; /* Hide the dropdown content by default */
    /* Add styling for the dropdown content as needed */
  }
  
  .dropdown:hover .dropdown-content {
    display: block; /* Show the dropdown content on hover */
  }
  
  .DigitalButton{
    height: 40px;
    width: 57vh;
    color: white;
    padding: 10px;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  }

   
  .DigitalButton:hover{
    background-color: black;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); 
  }

  .itemdigitalFooterDiv {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100vh;
    background-color: black;
  }
