/** @format */

.tableDiv {
  position: absolute;
  margin: auto;
  background-color: white;
  border: 1px solid #e3e3e3;
  width: 33%;
  height: 250px;
  top: 55px;
  left: 0;
  right: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 10000;
  overflow-y: scroll;
  overflow-x: hidden;
}

.searchDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
}

.searchRow {
  width: 80vh;
}

.fullWidthTable{
  width: 500vh;
  cursor: pointer;
  outline: none;
}
.fullWidthTable:hover  {
  background-color: #e3e3e3;
}

.searchIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  border-radius: 50%;
}

.searchTextDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.searchName {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 10px;
}

.searchHobby {
  position: relative;
  margin: 0;
  font-size: 13px;
  margin-left: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 2px solid #e3e3e3;
  border-top: 2px solid	#5D3FD3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
