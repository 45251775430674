/** @format */

.popularManageDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.popularMainDiv {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.popularCardDiv {
  position: relative;
  margin: 25px;
  width: 250px;
  height: 250px;
  background-color: whitesmoke;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.popularCardTopDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.popularProfilePic {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  margin: 0;
  margin-right: 5px;
  margin-top: 5px;
  object-fit: cover;
  object-position: center;
}

.popularProfileUsername {
  font-weight: bold;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0%;
  margin-top: 5px;
  margin-right: 10px;
  text-shadow: 1px 1px 1px #e3e3e3;
}

.popularCardBottomDiv {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding-top: 15px; padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
}

.popularLabel {
  font-weight: 500;
  font-size: 11px;
  margin: 0%;
  margin-left: 10px;
  text-align: left;
}

.popularLabel2 {
  font-weight: bold;
  font-size: 11px;
  margin: 0%;
  margin-right: 10px;
  cursor: pointer;
}

