.addRatingDialogDiv {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: 350px;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 14px;
    overflow: auto;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .addStarsContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    width: 100%;
  }
  
  .addStar {
    font-size: 30px;
    margin: 0 5px;
    cursor: pointer;
    color: lightgray;
    transition: color 0.3s;
    width: 100%;
  }
  
  .addStar.selected {
    color: gold;
  }

  .addStar:hover {
    color: gold;
  }
  
  .addRatingInput {
    width: 90%;
    margin: 10px auto;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    max-height: 200px;
  }
  
  .addRatingInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }

  .addRatingInput {
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 85%;
    border-radius: 6px;
    border: solid #e3e3e3;
  }
  
  .addRatingInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }
  
  .addConfirmButton {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    font-size: 16px;
    color: white;
    background-color: #5d3fd3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .addConfirmButton:hover {
    filter: brightness(85%); /* Reduces brightness by 15% */
  }
  
  .addRatingHeader {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }