.checkoutDiv {
    position: absolute;
    margin: auto;
    top: 62px;
    left: 0;
    margin-top: 2%; margin-bottom: 2%; margin-left: 15px;
    width: 80%; height: 80%;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    overflow: scroll;
}

.checkoutTable {
    position: relative;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 95%;
    border-spacing: 0 15px;
}

.checkoutHeader{
    color: black;
}
.checkoutTableContainer:hover {
    overflow: auto;
}

.checkoutHeader {
    position: relative;
    margin: auto;
    width: 100%;
    height: fit-content;
    border: 1px solid #e3e3e3;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.checkoutHeaderDiv {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.checkoutHeaderDivLeft {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
}

.checkoutHeaderDivRight {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.checkoutHeaderLabel {
    padding-left: 20px;
    padding-right: 20px;
}

.checkoutLabel {
    font-weight: bold;
    font-size: small;
}

.checkoutLabel2 {
    font-weight: 500;
    font-size: 11px;
    color: #36454F;
}

.checkoutLabelHighlight {
    font-weight: 300;
    font-size: 12px;
    font-style: normal;
    color: darkblue;
    cursor: pointer;
}

.checkoutLabelHighlight:hover {
    text-decoration: underline;
}

.checkoutBody {
    position: relative;
    width: 100%;
    height: fit-content;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: none;
    border: 1px solid #e3e3e3;
    display: flex;
}

.checkoutBodyDivLeft {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
}

.checkoutIcon {
    width: 75px;
    height: 75px;
    object-fit: fill;
    margin-left: 15px;
    border: 1px solid #e3e3e3;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.checkoutTextDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
}

.checkoutUsername {
    font-weight: bold;
    font-size: medium;
    margin-left: 5px;
    cursor: pointer;
}

.checkoutUsername:hover {
    text-decoration: underline;
}

.checkoutArrival {
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
}

.checkoutBodyDivRight {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.checkoutUpdate {
    width: 40%;
    height: 30px;
    background-color: white;
    color: #000;
    border: none;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: 5%;
    margin-right: 5%;
}

.checkoutSelected {
    width: 40%;
    height: 30px;
    background-color: #5d3fd3;
    color: #fff;
    border: none;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: 5%;
    margin-right: 5%;
}

.checkoutUpdate:hover,  .checkoutSelected:hover{
    background-color: black;
    color: #fff;
}

.checkoutPurchaseDiv{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    height: fit-content;
    right: 0;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 15px;
    width: 15%;
    background-color: white;
    border: 2px solid #e3e3e3;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-self: center;
    flex-direction: column;
}

.checkPurchaseLabels {
    text-align: center;
    margin-left: 5px; margin-right: 5px;
}

.checkPurchaseLabel {
    font-weight: 500;
    font-size: small;
}

.checkPurchaseLabel2 {
    font-weight: bold;
    font-size: small;
    color: #36454F;
}

.checkPurchaseLabel2Alt {
    margin: 8px;
    font-weight: bold;
    font-size: small;
    color: #36454F;
}

.checkPurchaseLabel3 {
    font-weight: bold;
    font-size: 10px;
    color: #5d3fd3;
    cursor: pointer;
}

.checkPurchaseButton{
    width: 90%;
    height: 30px;
    background-color: #FF2400;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 5%; margin-right: 5%; margin-bottom: 10px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
}

.checkPurchaseLabel3:hover {
    text-decoration: underline;
}

.checkPurchaseButton:hover {
    background-color: #C41E3A;
    color: #fff;
}

.footerDiv2 {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100%;
    background-color: black;
}

