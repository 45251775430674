.loading-completion-div{
  background-color: white;
  position: absolute;
  height: 100%; width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner-container{
  margin: 0;
}