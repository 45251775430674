/** @format */

.mini-bar {
  position: relative;
  margin: 0%;
  background-color: white;
  color: black;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  /* Add any other styles for the mini-bar */
}

.mini-img {
  position: relative;
  margin-left: 15px;
  cursor: pointer;
}

.title {
  position: relative;
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}

.search-bar {
  margin: auto;
  position: absolute;
  background-color: white;
  width: 30%;
  height: 35px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 20px;
  border-radius: 8px;
  border: solid #e3e3e3;
}

.search-bar:focus {
  border-color: #7f00ff;
  outline-color: #7f00ff;
}

.search-icon {
  margin: auto;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 28%;
  bottom: 0;
  right: 0;
  cursor: pointer;
  filter: invert(73%) sepia(8%) saturate(7%) hue-rotate(326deg) brightness(94%)
    contrast(82%);
}

.iconDiv {
  position: relative;
  margin-left: auto; /* This will push the element to the right */
  margin-right: 15px;
  display: flex;
  height: 100%;
  align-items: center;
}

.profile-img {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  /* Add other styles as needed */
}

.icon-textDiv{
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    align-items: center;
    cursor: pointer;
}

.icon-textDiv2{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    align-items: center;
}

.icon-img {
    position: relative;
    cursor: pointer;
    filter: invert(69%) sepia(52%) saturate(0%) hue-rotate(71deg) brightness(87%) contrast(85%);
}

.icon-img-red {
  position: relative;
  cursor: pointer;
  filter: invert(9%) sepia(97%) saturate(6035%) hue-rotate(341deg) brightness(98%) contrast(100%);
}

.icon-text{
    margin: 0; margin-top: 2px;
    font-size: 10px;
    font-weight: bold;
}

.icon-text-red{
  margin: 0; margin-top: 2px;
  font-size: 10px;
  font-weight: bold;
  color: red;
}

.icon-img:hover {
  filter: invert(11%) sepia(100%) saturate(7473%) hue-rotate(274deg) brightness(95%) contrast(120%);
}


.lineView2 {
  margin: 0%;
  position: relative;
  width: 100%;
  height: 0px;
  border: 1px solid #e3e3e3;
}
