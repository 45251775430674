.order-manageDiv{
    position: absolute;
    margin: auto;
    top: 62px; bottom: 0; left: 0; right: 0;
    margin-top: 2%;  margin-bottom: 2%;
    width: 80%;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
}

.orderTableDiv{
    position: absolute;
    margin: 0; 
    top: 0; bottom: 51px; left: 0; right: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.orderChoiceDiv{
    position: absolute;
    margin: 0;
    bottom: 0;
    width: 100%; height: 50px;
    border-top: 1px solid #e3e3e3; border-top-left-radius: 6px; border-top-right-radius: 6px;
    display: flex;
    cursor: pointer;
    z-index: 1;
}

.buyerChoiceDiv{
    width: 50%; height: 50px;
    border-top-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sellerChoiceDiv{
    width: 50%; height: 50px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choiceSelected {
    background-color: #e3e3e3;
  }

.choiceLabel{
    text-align: center;
    margin: 0; 
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.choiceErrorDiv{
    position: absolute;
    margin: auto;
    height: 100%; width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choiceErrorLabel{
    text-align: center;
    margin: 0; 
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.orderDiv{
    position: absolute;
    margin: auto;
    top: 62px; bottom: 0; left: 0; right: 0;
    margin-top: 2%;  margin-bottom: 2%;
    width: 80%;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    overflow: scroll;
}

.orderTable{
    position: relative;
    margin: auto;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 98%;
    height: 100%;
    border-spacing: 0 15px;
}

.orderTableContainer:hover{
    overflow: auto;
}

.orderHeader{
    position: relative;
    margin: auto;
    width: 100%;
    height: fit-content;
    border: 1px solid #e3e3e3;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.orderHeaderDiv{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.orderHeaderDivLeft{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: start;
}

.orderHeaderDivRight{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderHeaderLabel {
    padding-left: 20px;
    padding-right: 20px;
}

.orderLabel {
    font-weight: bold;
    font-size: small;
    text-align: center;
}

.orderLabel2 {
    font-weight: 300;
    font-size: 11px;
    font-style: normal;
    color: black;
    text-align: start;
}

.orderLabelHighlight {
    font-weight: 300;
    font-size: 12px;
    font-style: bold;
    color: #5D3FD3;
    cursor: pointer;
   
}


.orderLabelHighlight:hover {
    text-decoration: underline;
}

.orderBody{
    position: relative;
    width: 100%;
    height: fit-content;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: none;
    border: 1px solid #e3e3e3;
    display: flex;
    padding-top: 10px; padding-bottom: 10px;
}

.orderBodyDivLeft{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
}

.orderIcon{
    width: 75px;
    height: 75px;
    object-fit: fill;
    margin-left: 15px;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: 6px;
}

.orderTextDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
}

.orderUsername{
    font-weight: bold;
    font-size: medium;
    margin-left: 5px;
    text-align: left;
    cursor: pointer;
    width: 60vh;
    white-space: nowrap;    /* Prevent text from wrapping */
    overflow: hidden;       /* Hide overflowed content */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflow */
}

.orderUsername:hover, .orderArrival:hover{
    text-decoration: underline;
}

.orderArrival {
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.orderBodyDivRight{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderUpdate{
    width: 40%;
    height: 30px;
    background-color: white;
    color: #000;
    border: none;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: 5%;
    margin-right: 5%;
}

.orderUpdate:hover {
    background-color: black;
    color: #fff;
}
  
