.comingSoonDiv{
  position: absolute;
  width: 100%;
  background-color: whitesmoke;
  top: 0;
  bottom: 0;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.comingSoonLabel{
  font-weight: bold;
}