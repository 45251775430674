.buyChoiceDialogDiv {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: 350px;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 14px;
    overflow: auto;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buyChoiceLabelDiv{
    position: absolute;
    margin: auto; margin-top: 3px;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buyChoiceLabel{
    font-weight: bold;
    font-size: 14px;
  }

  .buyChoiceLabelOr{
    margin-left: 5px; margin-right: 5px;
    font-weight: bold;
    font-size: 14px;
  }

  .buyIconSociCoinChoice {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }

  .choiceCoinButton {
    height: 40px;
    margin: auto;
    background-color: white;
    color: #5d3fd3;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    width: calc(100% - 20px);
    max-width: 100%;
  }

  .choiceDisableButton {
    height: 40px;
    margin: auto;
    background-color: whitesmoke;
    color: #D70040;
    border: none;
    border-radius: 8px;
    cursor: none;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    width: calc(100% - 20px);
    max-width: 100%;
    pointer-events: none;
  }

  .choiceCashButton {
    height: 40px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
    width: calc(100% - 20px);
    max-width: 100%;
    margin-bottom: 15px;
  }

  .choiceCoinButton:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Adds shadow */
    background-color:#7F00FF;
    color: white;
  }
  
  .choiceCashButton:hover {
    background-color: black;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Adds shadow */
  }

  .orChoice {
    position: relative;
    margin: 0px; margin-top: 10px; margin-bottom: 10px; 
    font-size: 12px;
    width: 100%;
    font-weight: bold;
    text-align: center;
    border-radius: 16px;
  }
  