/** @format */

.ProfileDiv {
  position: relative;
  height: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;

}

.profilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  margin-right: 15px;
  object-fit: cover;
  object-position: center;
}

.foodPic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  margin-left: 15px;
}

.profileTextDiv {
  position: relative;
  display: flex;
  flex-direction: column;
}
.profileUsername {
  font-weight: bold;
  font-size: 20px;
  margin: 0%;
  margin-top: 5px;
}

.profileStatus {
  font-weight: 500;
  font-size: 14px;
  margin: 0%;
  margin-top: 6px;
}

.profileStar {
  font-weight: bold;
  font-size: 20px;
  margin: 0%;
  margin-top: 2px;
}

.profileBio {
  width: 300px;
  font-weight: 400;
  font-size: 14px;
  margin: 0%;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
}

.editDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  background-color: whitesmoke;
}

.profileButton {
  position: relative;
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  height: 35px;
  width: 150px;
  color: white;
  font-size: 14px;
  font-size: 100%;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.profileButton:hover {
  background-color: #cf9fff;
}

.profileTextDiv2 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
}

.profileStatusText {
  font-weight: bold;
  font-size: 16px;
  margin: 0%;
  margin-top: 10%;
}

.textLine {
  height: 100%;
  width: 1px;
  background-color: black;
}

.shadow-line{
  margin: 0;
  width: 100%;
  height: 1px;
  border: none;
  background-color: transparent; /* Make background transparent */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Create a shadow effect */
}

.item-container {
  position: relative;
  margin: 0%;
  min-height: 300px;
  width: 100%;
}

.profile-item-list {
  position: relative;
  margin: 0;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 96%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the start */
}

.profileItemView {
  position: relative;
  margin: 10px;
  width: calc(25% - 20px); /* Ensure 4 items per row with margins */
  box-sizing: border-box; /* Include padding and border in the width */
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  padding-bottom: 5px;
  cursor: pointer;
}

.profileItemView:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.profileItemImg{
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 16px;
}

.profileTypeImg{
  height: 30px;
  width: 30px;
  position: absolute;
  right: 15px; /* Position the element at the far right with some padding */
  top: 15px; /* Position the element at the top with some padding */
  z-index: 1000;
}

.profileBottomView{
  width: 90%;
  padding-left: 15px; padding-right: 15px;
  height: 1px; 
  background-color: #e3e3e3;
}

.profileItemButton {
  width: 90%;
  height: 35px;
  background-color: white;
  margin-bottom: 15px;
  color: #000;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ccc;
}

.profileItemButton:hover {
  background-color: #ba55d3;
  color: #fff;
}

.profileItemLabel{
  font-weight: bold;
  font-family: sans-serif;
  width: 230px; /* Define the desired width */
  overflow: hidden; /* Hide any content that overflows */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
}

.ProfileFooterDiv {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  background-color: black;
}

.copyrightLabel2 {
  margin: 0;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  color: white;
  padding-bottom: 10px;
}

.spinner {
  border: 5px solid #e3e3e3; /* Light grey */
  border-top: 5px solid #5d3fd3; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  animation: spin 1s linear infinite;
}