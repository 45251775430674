.optionDiv{
    margin: 0%;
    position: relative;
    height: fit-content;
    top: 0px; right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Customize the label (the container) */
.deleteContainer {
    position: relative;
    margin-top: 20px;
    margin-left: 35px;
    padding-left: 35px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 14px;
    font-family: Helvetica;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    display: flex;
}

/* Hide the browser's default checkbox */
.deleteContainer input {
    position: relative;
    margin: 0%;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;  
}

/* Create a custom checkbox */
.deleteCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: transparent;
    border: 1px solid #e3e3e3;
}

/* On mouse-over, add a grey background color */
.deleteContainer:hover input ~ .deleteCheckmark {
    background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.deleteContainer input:checked ~ .deleteCheckmark {
    background-color: #5D3FD3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.deleteCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.deleteContainer input:checked ~ .deleteCheckmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.deleteContainer .deleteCheckmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.deleteLabel {
    position: relative;
    top: 5px;
    text-align: center;
    font-size: medium;
    font-style: normal;
    font-family: Helvetica;
}

.deleteLabel2 {
    margin: auto;
    position: relative;
    top: 25px;
    text-align: center;
    font-size: small;
    font-style: normal;
    font-family: Helvetica;
    width: 70%;
    margin-bottom: 45px; /* Add margin at the bottom */
}

.deleteInput {
    margin: auto;
    position: relative;
    width: 70%;
    border-radius: 6px;
    height: 50px;
    font-size: 100%;
    padding-left: 20px;
    padding-top: 10px;
    border: solid #e3e3e3;
    font-family: Helvetica;
    resize: none;
}


.deleteInput:focus{
    border-color:#5D3FD3;
    outline-color: #5D3FD3;
}

.deleteButton{
    margin: auto;
    height: 40px;
    width: 70%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5D3FD3;
    top: 15px; 
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    margin-bottom: 30px;
}