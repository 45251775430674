.darkenDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8 ); /* Adjust the opacity value (0.5 in this example) for the desired darkness */
    z-index: 999; /* Ensure the element appears on top of other content */
    user-select: none;
}
  
.buyDialogImg {
    position: absolute;
    margin: auto;
    width: 30%;
    height: 75%;
    object-fit: fill;
    border-radius: 16px;
    left: 0; right: 0; top: 0; bottom: 15%;
}
  
.dialogArrowLeft{
    position: absolute;
    margin: auto; 
    width: 45px;
    height: 45px;
    border-radius: 35px;
    left: 10%; top: 0; bottom: 10%;
    cursor: pointer;
}

.dialogArrowRight {
  position: absolute;
  margin: auto; 
  width: 45px;
  height: 45px;
  border-radius: 35px;
  right: 10%; top: 0; bottom: 10%;
  cursor: pointer;
}

.dialogCross {
    position: absolute;
    margin: auto; 
    width: 45px;
    height: 45px;
    border-radius: 35px;
    right: 15px; top: 15px; 
    cursor: pointer;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(271deg) brightness(104%) contrast(102%);
}

.dialogArrowRight:hover,
.dialogArrowLeft:hover {
  background-color: transparent;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(271deg) brightness(104%) contrast(102%);
}

.buy-dialog-container {
    position: absolute;
    margin: auto;
    left: 0; right: 0;
    display: block;
    bottom: 3%;
    width: 70%;
    height: fit-content;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    gap: 15px;
}
  
.dialogbuy-item2 {
    display: flex;
    flex-direction: column; /* Added */
    background-color: white;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    flex: 0 0 calc(4%);
  }
  
  .dialogbuy-item2 img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    filter: blur(1px);
  }
  
  .dialogbuy-item2.selectedItem img{
    filter: blur(0px);
    border: 1px solid black;
  }
  

  .commentDarkenDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8 ); /* Adjust the opacity value (0.5 in this example) for the desired darkness */
    z-index: 999; /* Ensure the element appears on top of other content */
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Push content to the bottom */
    align-items: center; /* Center the content horizontally */
  }  

  .ratingTableWrapper {
    max-height: 80vh;
    background-color: white;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

  .ratingTableDialog{
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    border-top: 0px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.2);
    background-color: white;
    max-height: 200px !important;
    overflow-y: auto; 
    padding: 10px; 
  }

  .ratingLeftDialogLabel{
    margin-left: 15px;
    font-weight: bold;
    padding: 10px; 
  }
  
  .ratingRightDialogLabel{
    margin-right: 15px;
    font-weight: bold;
    color: #5d3fd3;
    cursor: pointer;
    padding: 10px; 
  }