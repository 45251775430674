.tosBackground{
    background-color: white;
    overflow-x: hidden;
}

.tosHeader{
  display: flex;
  height: fit-content;
  align-items: center;
  margin-top: 10px;
}

.Image {
    width: 50px;
    height: 50px;
    position: relative;
    margin-left: 10px;
}

.SociCoinImage {
    width: 50px;
    height: 50px;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
}

.tosHeaderLabel{
    font-size: large;
    margin-left: 10px;
    font-weight: bold;
}

.tosDiv{
    height: fit-content;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column; /* Ensures items are stacked vertically */
    align-items: center; /* Horizontally centers items */
    justify-content: center; /* Vertically centers items */
    border: solid 1px #e3e3e3;
}

.tosDivLabel{
    font-size: larger;
    margin-left: 5px;
    font-weight: bold;
}

.tosDivBody{
    text-align: center;
    width:80%
}

.tosFooterDiv {
    position: relative;
    margin: auto;
    display: block;
    width: 100%;
    background-color: black;
  }

  .copyrightLabel2{
    margin: 0;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: white;
    padding-bottom: 10px;
  }