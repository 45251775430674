/** @format */

.darkenDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Adjust the opacity value (0.5 in this example) for the desired darkness */
  z-index: 999; /* Ensure the element appears on top of other content */
  user-select: none;
  display: flex;
  align-items: center;
}

.bidDiv {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: fit-content;
  width: 350px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 14px;
  overflow: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeLeftDiv {
  width: 90%;
  border-radius: 12px;
  background-color: white;
  border: 1px solid darkgray;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

.timeLabelDiv {
  width: 90%;
  border-radius: 12px;
  background-color: white;
  border: 1px solid darkgray;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

.timeLeftIcon {
  height: 25px;
  width: 25px;
}

.timeLabelIcon {
  height: 30px;
  width: 30px;
}

.timeLeftLabel {
  position: relative;
  margin-left: 10px;
  font-weight: bold;
  color: black;
}

.timeButtonDiv {
    display: flex;
}

.bidButton {
    height: 40px;
    width: 150px;
    color: black;
    margin-top: 15px;
    padding-left: 10px; padding-right: 10px;
    margin-right: 5px;
    font-size: 100%;
    position: relative;
    background-color: #e3e3e3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
  }
  
  .bidButton2 {
    height: 40px;
    color: black;
    width: 150px;
    margin-top: 15px;
    padding-left: 10px; padding-right: 10px;
    margin-left: 5px;
    font-size: 100%;
    position: relative;
    background-color: #CCCCFF;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
  }

  .amountInput{
    width: 80%;
    -moz-appearance: textfield;
  }
  
  .amountInput::-webkit-inner-spin-button,
  .amountInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .bidButton:hover {
    color: white;
    background-color: black;
  }
  
  .bidButton2:hover {
    color: white;
    background-color: #5D3FD3;
  }
  