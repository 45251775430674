.footerDiv2 {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  top: 100%;
  background-color: black;
}

.notificationDiv {
  width: 50%;
  height: 75%;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  background-color: white;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 61px;
  padding: 15px;
  overflow-y: scroll;
}

.notificationTable {
  display: flex;
  padding: 5px;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 10px;
}

.notifIcon {
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  cursor: pointer;
}

.notifContent {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Ensures the button goes to the right */
  align-items: center;
}

.notifInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 5px;
}

.notifDivLabel {
  font-weight: bold;
  margin: 0%;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.notifDivLabel2 {
  margin: 0;
  margin-left: 5px;
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70vh;  /* Ensure text doesn't overflow past its container */
}

.noDataLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.notifButton {
  height: 40px;
  width: 20%; /* Change this based on your needs */
  color: white;
  font-size: 14px;
  padding-left: 5px; padding-right: 5px;
  margin-right: 10px;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
  justify-self: flex-end; /* Align the button to the right */
}

.notifButton:hover {
  background-color: black;
}
