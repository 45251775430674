/** @format */

.settingsDiv {
  position: absolute;
  right: 0;
  width: 200px;
  height: fit-content;
  z-index: 10;
  background-color: white;
  border: 1px solid #e3e3e3;
  padding-right: 15px;
}

.settingsDivView {
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
  border: 1px solid #e3e3e3;
  margin: 5px; margin-right: 35px;
  padding-top: 10px;
  padding-bottom: 10px; padding-left: 5px;
  border-radius: 6px;
  cursor: pointer;
}

.settingsImg {
  position: relative;
  margin: 0; /* This will push the element to the right */
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 50%;
  margin-left: 5px;
}
.settingsSvg {
  position: relative;
  margin: 0; /* This will push the element to the right */
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 50%;
  margin-left: 5px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%);

}
.settingsLabel {
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
}

.settingsDivView:hover .settingsLabel {
    color: #5d3fd3;
}