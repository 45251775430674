.applicationLeft{
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.applicationRight{
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.applicationLeftLabel{
    font-weight: bold;
    font-size: 18px;
    margin: 0%; margin-top: 15px;
}

.applicationLeftLabel2{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5%;
    margin-right: 5%;
}

.applicationJobDiv{
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    width: 100%;
    padding-bottom: 15px;
}

.applicationJobLabel{
    text-align: left;
    margin-left: 5%; margin-top: 20px;
    font-weight: bold;
}

.applicationTableLabel{
    text-align: left;
    margin: 0; margin-top: 10px;
    margin-left: 5%; margin-right: 5%;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.applicationRightDiv{
    width: 90%;
    height: 80px;
    background-color: white;
    border: 1px solid #e3e3e3;
    font-size: 14px;
    font-weight: 500;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.fileLabel{
    margin: 0; margin-left: 10px; margin-top: 5px;
    width: 90%;
    text-align: left;
    color: #5D3FD3;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.fileLabel:hover{
    color: black;
}
.resumeJobLabel{
    width: 90%;
    text-align: left;
    margin-left: 10px; margin-top: 20px;
    font-weight: bold;
}

.resumeInput{
    position: relative;
    margin: 0;
    height: 40px;
    color: black;
    font-size: 100%;
    width: 83%;
    border-radius: 6px;
    border: solid #e3e3e3;
}

.resumeButton {
    margin-left: auto; margin-right: auto;
    height: 40px;
    width: 90%;
    color: white;
    font-size: 100%;
    margin-top: 30px;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
  }
  