.digitalLayout{
    margin: auto;
    width: 75%;
    position: absolute;
    top: 85px; left: 0; bottom: 30px; right: 0;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
    border: solid #e3e3e3;
    display: flex;
}

.digitalLeftDiv{
    position: relative;
    width: 30%;
    border-right: solid 1px lightgray;
    height: 100%;
    overflow-y: auto;
 
}

.digitalRightDiv{
    position: relative;
    width: 70%; height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.digitalLeftTitle{
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgray;
    font-family: Helvetica;
    padding-top: 5px; padding-bottom: 5px;
}
.digitalLeftTitle2{
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgray; border-top: 1px solid #e3e3e3;
    font-family: Helvetica;
    padding-top: 5px; padding-bottom: 5px;
}
.digitalTitleDiv1{
    width: 100%;
    height: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    border-top: solid 1px lightgray;   border-bottom: solid 1px lightgray;
}

.digitalTitleDiv{
    width: 100%;
    height: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    border-bottom: solid 1px lightgray;
}
.digitalTitleDiv1.selected {
    background-color: #f0f0f0; /* Gray background color for the selected option */
}
.digitalTitleDiv.selected {
    background-color: #f0f0f0; /* Gray background color for the selected option */
}
.digitalTitleLabel{
    font-size: 14px;
    margin: 0; margin-left: 15px;
    font-weight: 500;
    font-family: Helvetica;
    color: black;
    
}
.digitalTitleIcon {
    margin: 0; margin-left: auto;  margin-right: 10px;
    height: 15px;
    width: 25px;
}

.digitalFooterDiv {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100%;
    background-color: black;
  }


  
  