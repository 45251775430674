.paymentSettingsDiv{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 100%;
}

.paymentSettingLabel{
   margin: 0;
   position: relative;
   font-size: small;
   top: 15px;
   margin-bottom: 15px;
}

.paymentChoiceButton {
   height: 40px;
   color: white;
   font-size: 100%;
   position: relative;
   background-color: black;
   width: 90%;
   margin-top:15px; 
   border-radius: 6px;
   border: solid #e3e3e3;
   cursor: pointer;
 }
 
 .paymentChoiceIcon {
   position: absolute;
   left: 6px;
   top: 50%;
   width: 25px;
   height: 25px;
   border: solid #e3e3e3;
   border-radius: 50%;
   transform: translateY(-50%);
 }
 
 .paymentChoiceButton:hover {
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
 }
 
.paymentErrorLabel{
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 0;
  color: black;
}