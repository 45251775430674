/** @format */

.applicationheader2 {
  position: absolute;
  display: flex;
  height: fit-content;
  align-items: center;
  margin-top: 10px;
}
.applicationHeaderLabel {
  font-size: large;
  margin-left: 10px;
  font-weight: bold;
}

.applicationConfDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* This ensures the container takes up the full viewport height */
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.applicationConfLabel {
  background-color: white;
  border: 1px solid #e3e3e3;
  padding: 15px;
  border-radius: 16px;
}

.applicationSocialLabel {
  margin: 0;
  margin-top: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.appIconDiv {
  margin-top: 10px;
  position: relative;
  display: flex;
  gap: 25px;
}

.icon {
  width: 45px;
  height: 45px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.icon:hover {
  transform: translateY(-4px);
}

.applicationFooterDiv {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  top: 100%;
  background-color: black;
}

.applicationButton2 {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  width: 50%;
  color: white;
  font-size: 100%;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 12px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.applicationButton2:hover {
  background-color: white;
  color: black;
}
