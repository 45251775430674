.addItemDiv {
    position: absolute;
    margin: 0;
    top: 61px;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
  }
  
  .addLeftDiv,
  .addRightDiv {
    flex: 1; /* Each div takes up equal space */
    width: 50%; /* Each div takes up 50% of the parent container */
  }
  
  .addLeftDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
 
  .addImageDiv{
    display: flex;
    align-items: center;
  }

  .addMiddleImage{
    width: 250px;
    height: 60vh;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
  }

  .addLeftIcon{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
    filter: invert(28%) sepia(95%) saturate(0%) hue-rotate(199deg) brightness(101%) contrast(92%);
  }

  .addRightIcon{
    height: 40px; width: 40px;
    margin-left: 10px;
    cursor: pointer;
    filter: invert(28%) sepia(95%) saturate(0%) hue-rotate(199deg) brightness(101%) contrast(92%);
  }

  .addLeftIcon:hover, .addRightIcon:hover{
    filter: none;
  }

  .add-item-container{
    width: 60vh; height: fit-content;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .addItemicon{
    width: 30px;
    height: 30px;
    margin-left: 5px; margin-right: 5px;
    border: 1px solid darkgrey;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
  }

  .addItemimage{
    width: 50px;
    height: 50px;
    margin-left: 10px;
    border: 1px solid darkgray;
    border-radius: 8px;
    cursor: pointer;
  }

  .selectedImg {
    border: 2px solid #5d3fd3;
    border-radius: 8px; /* Adjust the border radius as needed */
  }

  .addRightDiv {
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content to the top */
    background-color: white;
    overflow-y: auto;
  }
  
  .addRightItem{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .addItemNameLabel{
    margin: 0; 
    margin-top: 15px;
    font-weight: bold;
  }

  .addItemLabel{
    margin: 0; 
    font-weight: bold;
    margin-top: 10px;
  }
  
  .addItemInput{
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 10px; 
    padding-left: 20px;
    width: 50vh;
    border-radius: 6px;
    border: solid #e3e3e3;
    -moz-appearance: textfield;
  }

  .addItemInput::-webkit-inner-spin-button,
  .addItemInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .addItemInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }
  
  .addItemDescription{
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 5px; margin-bottom: 5px;
    padding-left: 20px; padding-top: 20px; padding-bottom: 20px; padding-right: 20px;
    width: 50vh;
    border-radius: 6px;
    border: solid #e3e3e3; 
  }

  .extra_list_container{
    margin: 0;
    border: 1px solid #e3e3e3; border-top: none;
    width: 56vh;
    padding-top: 10px; padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .extra_list_label{
    font-weight: bold;
    margin-left: 15px;
    width: 40vh;
    text-align: start;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden;    /* Hide the overflow text */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
  }

  .extra_list_icon{
    margin-right: 15px;
    width: 20px;
    height: 20px;
    filter: invert(84%) sepia(11%) saturate(8%) hue-rotate(20deg) brightness(97%) contrast(87%);
    cursor: pointer;
  }

  .extra_list_icon:hover{
    filter: invert(12%) sepia(76%) saturate(5852%) hue-rotate(341deg) brightness(90%) contrast(104%);
  }

  .itemQuantity {
    position: relative;
    width: 57vh;
    height: 40px;
    background-color: 	white;
    color: #000;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 50;
    margin-top: 10px; margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    text-align: left;
    padding-left: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
    appearance: none;
    background-image: url("./../img/downarrow.png");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
  }
  
  .itemQuantity:focus{
    outline: 2px solid #5d3fd3;
  }
  
  .dropdown-content {
    display: none; /* Hide the dropdown content by default */
    /* Add styling for the dropdown content as needed */
  }
  
  .dropdown:hover .dropdown-content {
    display: block; /* Show the dropdown content on hover */
  }
  
  .itemButton{
    height: 40px;
    width: 57vh;
    color: white;
    padding: 10px;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    margin-bottom: 15px;
  }

   
  .itemButton:hover{
    background-color: black;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); 
  }

  .feesLabel{
    position: relative;
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
  }