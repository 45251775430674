/** @format */
.divBox {
  margin: auto;
  width: 40%;
  height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.divImage {
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 10px;
}

.divRegisterLabel{
    font-size: small;
}

.backDiv{
    position: absolute;
    left: 5%; 
    top: 15px;
    font-weight: bold;
    font-size: small;
    cursor: pointer;
}

.backDiv:hover{

}

input {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 85%;
  border-radius: 6px;
  border: solid #e3e3e3;
}

input:focus {
  border-color: #5d3fd3;
  outline-color: #5d3fd3;
}

.date-input{
   display: flex;
   justify-content: space-between;
}

.date-input {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
  
.date-input input {
   margin-left: 5px; /* Adjust this value to control the spacing */
   margin-right: 5px;
}
  
.regtosLabel {
    position: relative;
    font-size: small;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    width: 80%
}

.regButton {
    margin-left: auto; margin-right: auto;
    height: 40px;
    width: 90%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .loginFooterDiv {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100%;
    background-color: black;
  }
  