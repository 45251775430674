.categoryItemTable{
    position: relative;
    width: 20%; /* Set each item to occupy 20% of the container width */
    background-color: white;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    padding-bottom: 10px;
    margin: 10px 10px 15px;
  }

.categoryItemImage{
    margin: 0;
    width: 100%;
    height: 200px;
    object-fit: fill;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .coinImg2{
    position: relative;
    margin: 0; /* This will push the element to the right */
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }
  

.categoryItemLabelName{
    color: black;
    font-weight: bold; /* Make the text bold */
    margin-left: 3px;
    font-size: 14px;
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Hides any overflowing text */
    text-overflow: ellipsis; /* Adds the ellipsis (...) when the text is too long */
    max-width: 12ch; /* Limits the text to 12 characters */
}

.categoryDivLabelSpacing{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px; margin-right: 8px;
}

.categoryStarsLabel{
}