.ratingViewBox {
  margin: auto;
  width: 50%;
  position: absolute;
  top: 85px;
  left: 0;
  bottom: 30px;
  right: 0;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
}

.ratingViewHeaderDiv {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: whitesmoke;
}

.ratingViewLeftLabel {
  font-weight: bold;
  margin-left: 20px;
}

.ratingViewRightLabel {
  font-weight: bold;
  margin-right: 20px;
}

.ratingViewList {
    width: 100%;
    height: 100%; /* Set a maximum height to make the area scrollable */
    align-items: center;
    justify-content: center;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling if unnecessary */
  }
  
.ratingViewTable {
  width: 100%;
}

.ratingViewEmptyDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratingViewEmpty {
  font-weight: bold;
}

.ratingViewLine {
  display: flex;
  cursor: pointer;
}

.ratingViewUsername {
  position: relative;
  left: 20px;
  font-weight: bold;
  font-size: medium;
  width: 70vh;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add an ellipsis to the overflowed text */
}

.ratingViewRating {
  position: relative;
  right: 20px;
  font-weight: bold;
  font-size: medium;
  margin-left: auto;
}

.ratingViewAdditional {
    position: relative;
    left: 20px;
    margin-top: 0%;
    display: inline-block;
    width: calc(100% - 50px); /* Subtract the margin-right value */
    font-style: bold;
    font-size: medium;
    text-align: left;
    margin-right: 50px;
    word-break: break-word;
  }
  