.messageDiv {
  background-color: whitesmoke;
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  justify-content: space-between;
  z-index: 1px;
}

.leftMessageDiv {
  flex: 1;
  margin: 15px;
  background-color: white; /* Example background color */
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  
}

.leftMessageContainer{

}


.messageListDiv{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}

.messageListDivFirst{
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-top-left-radius: 15px; border-top-right-radius: 15px;
}

.message_selected {
  background-color: whitesmoke;
}

.messageListIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  border-radius: 50%;
}

.messageMainTextDiv{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.messageListTextDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.messageListName {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 10px;
  margin-bottom: 2px;
  white-space: nowrap;      /* Prevents line wrapping */
  overflow: hidden;         /* Hides overflowed content */
  text-overflow: ellipsis;  /* Adds ellipsis */
}

.messageListHobby {
  position: relative;
  margin: 0;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 2px;
  width: 75%;
  text-align: start;
  white-space: nowrap;      /* Prevents line wrapping */
  overflow: hidden;         /* Hides overflowed content */
  text-overflow: ellipsis;  /* Adds ellipsis */
}

.messageListTextDiv2 {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 5px;
}
.messageListCount {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 10px;
  color: red;
  margin-bottom: 2px;
  text-align: right;
  white-space: nowrap;      /* Prevents line wrapping */
  overflow: hidden;         /* Hides overflowed content */
  text-overflow: ellipsis;  /* Adds ellipsis */
}

.messageListTimeString {
  position: relative;
  margin: 0;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 2px;
  white-space: nowrap;      /* Prevents line wrapping */
  overflow: hidden;         /* Hides overflowed content */
  text-overflow: ellipsis;  /* Adds ellipsis */
}

.rightMessageDiv {
  flex: 3;
  margin: 15px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.rightMessageToolbar {
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.message-list {
  display: flex;
  flex-direction: column-reverse; /* This keeps the most recent messages at the bottom */
  overflow-y: auto; /* Enables vertical scrolling */
  height: 200px; /* Or adjust to your preferred height */
  max-height: 100%; /* Or define a max height based on your layout */
  padding: 10px;
  border: 1px solid #ddd; /* Optional border for visual reference */
}


.messageIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  border-radius: 50%;
}

.messageToolbarTextDiv {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.messageName {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 10px;
}

.messageAdditional {
  position: relative;
  margin: 0;
  font-size: 13px;
  margin-left: 10px;
}

.message_line {
  margin: 0;
  height: 1px;
  background-color: #e3e3e3;
  width: 100%;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
  
  .message-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .message-input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
    border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;
  }
  
  .message-text-input {
    flex: 1;
    padding: 8px; padding-left: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0px;
    margin-right: 10px;
    height: 30px;
  }
  
  .message_button {
    margin: 0px;
    padding: 8px 24px;
    background-color: #7F00FF;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
  }

  .message_button:hover {
    background-color: #5D3FD3;
  }
  
  .message-loading-container {
    position: relative;
    margin: 0%;
    min-height: 100%;
    width: 100%;
  }
  
  .message-bubble-right {
    background-color: #7F00FF; /* Purple color */
    color: white; /* Text color */
    padding: 10px 15px; /* Padding for the bubble */
    border-radius: 15px; /* Rounded corners */
    max-width: 70%; /* Limit the bubble width */
    margin: 5px 0; /* Vertical margin between bubbles */
    align-self: flex-end; /* Align the bubble to the right */
    word-wrap: break-word; /* Handle long words properly */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for a nicer effect */
  }
  
  .message-bubble-left {
    background-color: #e3e3e3; /* Purple color */
    color: black; /* Text color */
    padding: 10px 15px; /* Padding for the bubble */
    border-radius: 15px; /* Rounded corners */
    max-width: 70%; /* Limit the bubble width */
    margin: 5px 0; /* Vertical margin between bubbles */
    align-self: flex-start; /* Align the bubble to the right */
    word-wrap: break-word; /* Handle long words properly */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for a nicer effect */
  }
  