.safetyDialogDiv {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: fit-content;
  width: 80vh;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 14px;
  overflow: auto;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.safetyDivTitle{
    font-weight: bold;
}

.safetyDivLabel {
  margin-top: 10px; /* Space between image and text */
  font-size: 16px; /* Adjust text size as needed */
  font-weight: 500;
  padding: 15px;
  background-color: #e3e3e3;
  border-radius: 16px;
  cursor: pointer;
}

.safetyDivLabel:hover{
    color: #5D3FD3;
}