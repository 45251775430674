.profileItemDiv {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: fit-content;
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 14px;
    overflow: auto;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profileMainDiv{
    cursor: pointer;
  }

  .profileMainDiv:hover .profileCardLabel{
    font-weight: bold;
  }

  .profileMainDiv:hover .profileCardDiv{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); 
  }

  .profileCardDiv{
    width: 40vh; height: 50vh;
    margin-left: 30px; margin-right: 30px;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    transition: box-shadow 0.3s ease;
  }

  .profileCardImage{
    width: 40vh; height: 50vh;
    border-radius: 12px;
    object-fit: cover;
  }

  .profileCardLabel {
    margin: 0; margin-top: 10px;
    position: relative;
    font-weight: 500;
    color: black;
  }
  