.adminDiv {
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
}

.saleDivBox {
  margin: auto;
  width: 40%;
  height: fit-content;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border: solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  padding: 20px;
}

.orderTable {
  position: relative;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 95%;
  height: 95%;
  border-spacing: 0 15px;
}

.orderTableContainer:hover {
  overflow: auto;
}

.orderHeader {
  position: relative;
  margin: auto;
  width: 100%;
  height: fit-content;
  border: 1px solid #e3e3e3;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.orderHeaderDiv {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.orderHeaderDivLeft {
  height: 100%;
  width: 50%;
  align-items: center;
}

.orderHeaderDivRight {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderHeaderLabel {
  padding-left: 20px;
  padding-right: 20px;
}

.orderLabel {
  font-weight: bold;
  font-size: small;
}

.orderLabel2 {
  font-weight: 300;
  font-size: 11px;
  font-style: normal;
  color: black;
}

.orderLabelHighlight {
  font-weight: 300;
  font-size: 12px;
  font-style: normal;
  color: #0047ab;
  cursor: pointer;
}

.orderLabelHighlight:hover {
  text-decoration: underline;
}

.orderBody {
  position: relative;
  width: 100%;
  height: fit-content;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: none;
  border: 1px solid #e3e3e3;
  display: flex;
}

.orderBodyDivLeft {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
}

.orderIcon {
  width: 75px;
  height: 75px;
  object-fit: fill;
  margin-left: 15px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 6px;
}

.orderTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
}

.orderUsername {
  font-weight: bold;
  font-size: medium;
  margin-left: 5px;
}

.orderUsername:hover {
  text-decoration: underline;
}

.orderArrival {
  font-weight: 500;
  font-size: small;
  margin-left: 5px;
}

.orderBodyDivRight {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.adminUpdate {
  width: 60%;
  height: 30px;
  background-color: white;
  color: #000;
  border: none;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-left: 5%;
  margin-right: 5%;
}

.adminUpdate:hover {
  background-color: black;
  color: #fff;
}

.updateTitle {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 14px;
}

.updateLabel {
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  padding-left: 35px;
}

.updateInput {
  position: relative;
  margin: auto;
  margin-bottom: 15px;
  height: 45px;
  color: black;
  font-size: 100%;
  padding-left: 20px;
  width: 95%;
  border-radius: 6px;
  border: solid #e3e3e3;
  box-sizing: border-box; /* Ensures padding and border are included in width */
}

.updateInput input {
  width: 100%; /* Full width of the parent container */
  height: 100%; /* Match the height of the parent container */
  padding: 0; /* Remove default padding if any */
  box-sizing: border-box; /* Ensures padding and border are included in width */
  border: none; /* Remove border if you only want the container's border */
}

/* .updateInput { 
  position: relative;
  margin: auto;
  margin-bottom: 15px;
  height: 40px;
  color: black;
  font-size: 100%;
  position: relative;
  padding-left: 20px;
  width: calc(
    100% 
  );
  border-radius: 6px;
  border: solid #e3e3e3;
} */

.updateButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px;
  width: 95%;
  color: white;
  font-size: 100%;
  position: relative;
  background-color: #5d3fd3;
  border-radius: 6px;
  border: solid #e3e3e3;
  cursor: pointer;
}

.updateButton:hover {
  background-color: black;
  color: white;
}
.orderFooterDiv {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  top: 100%;
  background-color: #1f51ff;
}

.buyStatusChange {
  position: relative;
  height: 45px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  background-color: white;
  color: #000;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 50;
  border: 1px solid #e3e3e3;
  text-align: left;
  padding-left: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("./../img/downarrow.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
  width: 95%;
  max-width: 100%; /* Ensure it doesn't overflow */
}

.buyStatusChange:focus {
  outline: 2px solid #5d3fd3;
}

@media (max-width: 900px) {
  .orderHeaderDivRight {
    display: none;
  }

  .orderUsername {
    font-size: 10px;
  }

  .orderArrival {
    font-size: 8px;
  }

  .orderIcon {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .adminUpdate {
    width: 60%;
    height: 30px;
    margin-top: 10px;
    font-size: 8px;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
  }
}
