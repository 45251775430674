.orderProofDiv {
    border: 1px solid #e3e3e3;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 95%;
    border-radius: 8px;
    max-height: 15vh;
    display: flex;
    overflow-y: auto; /* Add this to enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.orderProofDialogLabel{
    margin: 0; margin-left: 15px;
    text-align: left;
}

.order-spinner-container {
    height: 200px;
  }