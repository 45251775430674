/** @format */

.securitySettingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.securitySettingLabel {
  margin: 0;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.divInput {
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    color: black;
    font-size: 100%;
    position: relative;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 85%;
    border-radius: 6px;
    border: solid #e3e3e3;
    
  }
  
  .divInput:focus {
    border-color: #5d3fd3;
    outline-color: #5d3fd3;
  }

  .securityErrorLabel{
    font-weight: 500;
    font-size: small;
    margin-bottom: 0;
    color: black;
  }

  .divButton {
    margin-left: auto; margin-right: auto;
    height: 40px;
    width: 90%;
    color: white;
    font-size: 100%;
    position: relative;
    background-color: #5d3fd3;
    border-radius: 6px;
    border: solid #e3e3e3;
    cursor: pointer;
    margin-top: 15px;
  }
