.homeBox {
    margin: auto;
    width: 75%;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border: solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
  }
  
  .FooterDiv {
    position: relative;
    margin: auto;
    display: block;
    width: 100%;
    background-color: black;
  }

  .copyrightLabel2{
    margin: 0;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: white;
    padding-bottom: 10px;
  }