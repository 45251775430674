.addressBox {
    margin: auto;
    width: 50%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border: solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .addressTitle {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-align: center;
    margin-top: 20px;
  }
  
  .addressInput {
    margin: 0;
    height: 40px;
    width: 80%;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    border: solid #e3e3e3;
    resize: none;
    overflow: auto;
    font-weight: 500;
    font-size: 16px;
    font-family: Arial, sans-serif;
    border-radius: 16px;
  }
  
  .addressButton {
    width: 40%;
    height: 40px;
    background-color: #5d3fd3;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    margin-top: 10px;  margin-bottom: 10px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #e3e3e3;
  }
  
    .body-blur {
      /* overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
      pointer-events: none; */
    }
  
    