.footerDiv2 {
    position: absolute;
    margin: auto;
    display: block;
    width: 100%;
    top: 100%;
    background-color: black;
  }

  .instructionDiv {
    width: 35%;
    height: fit-content;
    border-radius: 16px;
    border: 1px solid #e3e3e3;
    background-color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 35px;
    padding: 15px;
  }

  .instructionTitle{
    margin-top: 0;
    font-weight: bold;
    text-align: center;
    font-size: large;
  }
  .instructionLabel{
    text-align: center;
  }

  .instructionLabel2{
    text-align: center;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    margin-left: 15px; margin-right: 15px;
  }
  
  .instructionView{
    margin: 0;
    width: 100%;
    height: 1px; 
    background-color: #e3e3e3;
  }
  
  .buyCoinDiv {
    width: 50%;
    height: 60%;
    border-radius: 16px;
    border: 1px solid #e3e3e3;
    background-color: white;
    position: absolute;
    top: 50%; 
    left: 45%;
    transform: translateY(-50%);
    padding: 15px;
    overflow-y: scroll;
  }

  .cashButton {
    width: 100%;
    height: 35px;
    background-color: white;
    color: #000;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #ccc;
  }

  .cashButton:hover {
    background-color: #5d3fd3;
    color: white;
  }
 
  .coinTableDiv{
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .coinDivLabel{
    font-weight: bold;
    margin: 0%;
    margin-left: 5px;
  }

  .coinDivLabel2{
    font-family: 'Comic Sans MS', cursive, sans-serif;
    margin: 0%;
    margin-left: 5px;
  }
  
  .coinDivButton {
    width: 150px;
    height: 35px;
    margin-left: auto;
    background-color: #5d3fd3;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #ccc;
  }

  .coinDivButton:hover {
    background-color: white;
    color: #000;
  }

