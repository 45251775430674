.itemBuyDiv {
  display: flex; /* Flexbox layout */
  position: relative; /* Ensure relative positioning if needed for child positioning */
}

.leftItemBuyDiv {
  flex: 6; /* Relative width to other flex items, can use a ratio like 7:3 */
  padding: 16px; /* Add padding for internal spacing */
  background-color: white;
  border-right: 1px solid #e3e3e3;
}

.rightItemBuyDiv {
  flex: 4; /* Relative width to other flex items */
  padding: 16px; /* Add padding for internal spacing */
  background-color: white;
}

.buyImgContainer {
  position: relative;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.buyImg {
  position: relative; /* Add position relative to create a positioning context */
  width: 50%;
  height: 100%;
  object-fit: fill;
  border: 3px solid #e3e3e3;
  border-radius: 16px;
  cursor: zoom-in;
  user-select: none; /* Prevent text selection */
}

.buyImg:focus {
  outline: none;
  box-shadow: none;
}

.bArrowLeft,
.bArrowRight {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 35px;
}

.bArrowRight:hover,
.bArrowLeft:hover {
  filter: invert(68%) sepia(3%) saturate(6%) hue-rotate(17deg) brightness(98%)
    contrast(95%);
  background-color: transparent;
}

.buy-container {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  height: fit-content;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  gap: 15px;
}

.buy-item2 {
  display: flex;
  flex-direction: column; /* Added */
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;
  flex: 0 0 calc(10%);
}

.buy-item2 img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  filter: blur(1px);
  user-select: none; /* Prevent text selection */
}

.buy-item2.selectedItem img {
  filter: blur(0px);
  border: 1px solid black;
}

.subImage {
  height: 90px !important;
  width: 200px !important;
  object-fit: fill !important;
}

.ratingName {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
  margin-top: 35px;
  margin-bottom: 15px;
}

.ratingDiv {
  width: 100%;
  height: 40px;
  background-color: white;
  color: #000;
  border: none;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  font-weight: bold;
  margin-left: 1px;
  margin-right: 1px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.ratingLabelsDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ratingLeftLabel {
  margin-left: 15px;
  font-weight: bold;
}

.ratingRightLabel {
  margin-right: 15px;
  font-weight: bold;
  color: #5d3fd3;
  cursor: pointer;
}

.ratingRightLabel:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.ratingTable {
  width: 100%;
  margin-left: 1px;
  margin-right: 1px;
  border-top: 0px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.2);
}

.ratingLine {
  display: flex;
}

.buytableUsername {
  position: relative;
  left: 20px;
  font-weight: bold;
  font-size: medium;
}

.buytableRating {
  position: relative;
  right: 20px;
  font-weight: bold;
  font-size: medium;
  margin-left: auto;
}

.buytableAdditional {
  position: relative;
  left: 20px;
  margin-top: 0%;
  display: inline-block;
  width: calc(100% - 50px); /* Subtract the margin-right value */
  font-style: bold;
  font-size: medium;
  text-align: left;
  margin-right: 50px;
  word-break: break-word;
}

.spinner-container-page {
  width: 50px;
  height: 91vh;
  margin: auto;
  position: relative;
  animation: spin 1s linear infinite;
}

.spinner {
  border: 5px solid #e3e3e3; /* Light grey */
  border-top: 5px solid #5d3fd3; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: spin 1s linear infinite;
}

.buyLabel {
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-wrap: break-word; /* or overflow-wrap: break-word; */
}

.buyName {
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  overflow-wrap: break-word; /* or overflow-wrap: break-word; */
}

.buyPriceDiv {
  display: flex;
  flex-direction: row;
  padding: 5;
}

.buyPrice {
  margin: 0;
  position: relative;
  font-size: 17px;
  width: fit-content;
  font-weight: bold;
  margin-left: 10px;
}

.buyOr {
  margin: 0;
  position: relative;
  font-size: 17px;
  width: fit-content;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.buyPriceSociCoin {
  margin: 0;
  position: relative;
  font-size: 17px;
  width: fit-content;
  font-weight: bold;
  color: #5d3fd3;
}

.buyIconSociCoin {
  margin: 0;
  margin-right: 5px;
  width: 22px;
  height: 22px;
}

.buyDescriptionDiv {
  position: relative;
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  height: 250px;
  border: 1px solid #f0f0f0;
  background-color: #f8f8f8;
  border-radius: 15px;
  overflow: hidden; /* Ensure overflow is controlled by the div */
}

.buyDescriptionText {
  width: 100%; /* Set to 100% to fill the div horizontally */
  height: 100%; /* Also 100% height to match the div */
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  border: none;
  overflow: auto; /* Ensure scrolling works */
  resize: none; /* Disable resize to keep dimensions fixed */
  box-sizing: border-box; /* Ensure padding is included within the height */
}

.buyDescriptionText:focus {
  outline: none; /* Ensure no outline when focused */
  box-shadow: none; /* Ensure no shadow when focused */
}

.buyQuantity {
  position: relative;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
  color: #000;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 50;
  border: 1px solid #e3e3e3;
  text-align: left;
  padding-left: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  appearance: none;
  background-image: url("./../img/downarrow.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;

  /* Set width to respect margins */
  width: calc(
    100% - 20px
  ); /* 100% width minus the 10px margins on both sides */
  max-width: 100%; /* Ensure it doesn't overflow */
}

.buyQuantity:focus {
  outline: 2px solid #5d3fd3;
}

.buyCart {
  height: 40px;
  margin: auto;
  background-color: white;
  margin-top: 25px;
  color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  max-width: 100%;
}

.buyOrCart {
  position: relative;
  margin: 0px; margin-top: 10px; margin-bottom: 10px; 
  font-size: 12px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  border-radius: 16px;
}

.buyNowCart {
  height: 40px;
  background-color: #5d3fd3;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  width: calc(100% - 20px);
  max-width: 100%;
}

.buyCart:hover,
.buyNowCart:hover,
.buyMessageButton:hover {
  background-color: black;
  color: #fff;
}

.buyDeliveryDiv {
  margin: 0;
}

.deliveryDiv {
  display: flex;
  align-items: center;
}

.deliveryName {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.deliveryDigitalLabel {
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-left: 10px;
  overflow-wrap: break-word; /* or overflow-wrap: break-word; */
}

.deliveryAdditional {
  margin: 0;
  margin-left: 5px;
  font-size: 7px;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds an ellipsis (…) when the text overflows */
}

.deliveryImg {
  width: 35px; /* Adjust the width of the image */
  height: 25px; /* Adjust the height of the image */
  cursor: pointer;
  filter: invert(35%) sepia(6%) saturate(10%) hue-rotate(36deg) brightness(92%)
    contrast(86%);
}

.returnImg {
  width: 35px;
  height: 40px; /* Keep the width and height as is */
  cursor: pointer;
  filter: invert(35%) sepia(6%) saturate(10%) hue-rotate(36deg) brightness(92%)
    contrast(86%);
  transform: scale(1.5); /* Increase the size by 1.5x */
}

.buyMessageButton {
  height: 40px;
  background-color: #1434a4;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  width: calc(100% - 20px);
  max-width: 100%;
  margin-bottom: 15px;
}

.buyDeliveryMessageButton {
  height: 40px;
  background-color: #1434a4;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #e3e3e3;
  width: calc(100% - 20px);
  max-width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.buyDeliveryMessageButton:hover {
  background-color: black;
}

.dropdown-content {
  display: none; /* Hide the dropdown content by default */
  /* Add styling for the dropdown content as needed */
}

.dropdown:hover .dropdown-content {
  display: block; /* Show the dropdown content on hover */
}

.digitalAudioDiv {
  position: relative;
  width: 50%;
  margin: 10px auto; /* Center horizontally with top margin */
  display: flex;
  border: solid 1px #e3e3e3;
  
}

.digitalMusicDiv{
  position: relative;
  width: 100%;
  height: 50px;
  background-color: white;
  border: 1px solid whitesmoke;
  border-top-right-radius: 4px; border-bottom-right-radius: 4px;
  cursor: pointer;
  object-fit: cover;
  display: flex;
  justify-content: space-between;
}

.childrenDigitalAudioDiv{
  position: relative;
  width: 100%;
}

.buySeekbarDiv{
  width: 50%;
  margin: 0px auto; 
}

.digitalSeekbar {
  -webkit-appearance: none;  /* Remove default appearance in WebKit browsers */
  appearance: none;
  width: 100%; /* Full width */
  height: 10px; /* Height of the slider */
  background: linear-gradient(to right, black 0%, black 0%, #ddd 0%, #ddd 100%); /* Progress bar (black for filled, grey for unfilled) */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Semi-transparent */
  transition: opacity .2s; /* Smooth transition */
  margin: 0; margin-top: 15px;
  padding: 0; /* Remove any default padding */
  appearance: none; /* Remove default browser styling */
}

/* Handle (thumb) of the slider */
.digitalSeekbar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Width of the handle */
  height: 20px; /* Height of the handle */
  background: #5d3fd3; /* Color of the handle */
  border-radius: 50%; /* Rounded handle */
  cursor: pointer; /* Cursor style */
}

.digitalSeekbar::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #5d3fd3;
  border-radius: 50%;
  cursor: pointer;
}

/* Hover effect for the seekbar */
.digitalSeekbar:hover {
  opacity: 1;
}

.buyDigitalShopDiv{
  margin-top: 25px;
}